import type { NavItem } from '@hubcms/domain-navigation';
import { NavigationFocusBlock } from '@hubcms/ui-navigation-2';

import { NavigationItem } from '../NavigationItem';

import styles from './aside-menu.module.scss';

type AsideMenuFocusSectionProps = {
  items: NavItem[];
};

export function AsideMenuFocusSection({ items }: AsideMenuFocusSectionProps) {
  if (items.length > 0) {
    return (
      <section className={styles.focusSection}>
        <nav className={styles.focusNav}>
          {items.map(item => (
            <NavigationItem key={item.uniqueId} navItem={item}>
              {({ href }) => <NavigationFocusBlock href={href} label={item.text} />}
            </NavigationItem>
          ))}
        </nav>
      </section>
    );
  }

  return null;
}
