import { IconButton } from '@mediahuis/chameleon-react';
import { MenuClose } from '@mediahuis/chameleon-theme-wl/icons';

import { SearchBar } from '@hubcms/ui-navigation-2';

import styles from './aside-menu.module.scss';

type AsideMenuSearchSectionProps = {
  placeholder: string;
  onClick: () => void;
  onSearch: (searchValue: string) => void;
};

export function AsideMenuSearchSection({ placeholder, onClick, onSearch }: AsideMenuSearchSectionProps) {
  return (
    <section className={styles.searchSection}>
      <IconButton
        aria-label="Menu"
        className={styles.closeButton}
        data-testid="navigation-menu-close"
        icon={MenuClose}
        onClick={onClick}
      />

      <SearchBar placeholder={placeholder} onSubmit={onSearch} />
    </section>
  );
}
