import type { NavItem } from '@hubcms/domain-navigation';
import { MenuItem } from '@hubcms/ui-navigation-2';

import { NavigationItem } from '../NavigationItem';

import styles from './aside-menu.module.scss';

type AsideMenuMainSectionProps = {
  primaryItems: NavItem[];
  secondaryItems: NavItem[];
};

export function AsideMenuMainSection({ primaryItems, secondaryItems }: AsideMenuMainSectionProps) {
  const hasPrimaryItems = primaryItems.length > 0;
  const hasSecondaryItems = secondaryItems.length > 0;

  if (hasPrimaryItems || hasSecondaryItems) {
    return (
      <section className={styles.mainSection}>
        {hasPrimaryItems && <PrimaryNav items={primaryItems} />}
        {hasSecondaryItems && <SecondaryNav items={secondaryItems} />}
      </section>
    );
  }

  return null;
}

type PrimaryNavProps = {
  items: NavItem[];
};

function PrimaryNav({ items }: PrimaryNavProps) {
  return (
    <nav className={styles.primaryNav}>
      {items.map(item => (
        <NavigationItem navItem={item} key={item.uniqueId}>
          {({ href }) => (
            <MenuItem
              href={href}
              rightIconName={item.image}
              leftIconName="ArrowForward"
              text={item.text}
              target={item.target}
              trackingBlock="navigation-menu-primary"
            />
          )}
        </NavigationItem>
      ))}
    </nav>
  );
}

type SecondaryNavProps = {
  items: NavItem[];
};

function SecondaryNav({ items }: SecondaryNavProps) {
  return (
    <nav className={styles.secondaryNav}>
      {items.map(item => {
        return (
          <NavigationItem navItem={item} key={item.uniqueId}>
            {({ href }) => (
              <MenuItem
                variant="secondary"
                href={href}
                text={item.text}
                trackingBlock="navigation-menu-secondary"
                target={item.target}
              />
            )}
          </NavigationItem>
        );
      })}
    </nav>
  );
}
