import { LinkText } from '@mediahuis/chameleon-react';
import cx from 'classnames';
import { useRef } from 'react';
import { useEventListener } from 'usehooks-ts';

import { authMethod, logoutLabel } from '@hubcms/brand';
import { useAuth } from '@hubcms/data-access-auth';
import { useEnv } from '@hubcms/data-access-env';
import { useNavigation } from '@hubcms/data-access-navigation';
import { sendTrackingEvent } from '@hubcms/data-access-tracking';
import type { NavItem } from '@hubcms/domain-navigation';
import { getLogoutRedirectUrl } from '@hubcms/utils-auth';
import { createMenuEvent } from '@hubcms/utils-tracking';

import { AsideMenuFocusSection } from './AsideMenuFocusSection';
import { AsideMenuLinksSection, type AsideMenuLinksSectionProps } from './AsideMenuLinksSection';
import { AsideMenuMainSection } from './AsideMenuMainSection';
import { AsideMenuPaperSection } from './AsideMenuPaperSection';
import { AsideMenuSearchSection } from './AsideMenuSearchSection';
import styles from './aside-menu.module.scss';

type AsideMenuLinkSection = Omit<AsideMenuLinksSectionProps, 'extraElement'>;

export type AsideMenuProps = {
  accountSection: AsideMenuLinkSection;
  focusItems: NavItem[];
  primaryItems: NavItem[];
  searchInputPlaceholder: string;
  secondaryItems: NavItem[];
  servicesSection: AsideMenuLinkSection;
  onSearch: (searchTerm: string) => void;
};

type AsideMenuBaseProps = AsideMenuProps & {
  isAuthenticated: boolean;
  isOpen: boolean;
  onClose: () => void;
  onLogout: () => void;
};

export function AsideMenuBase({
  accountSection,
  focusItems,
  isAuthenticated,
  isOpen,
  onClose,
  onLogout,
  primaryItems,
  searchInputPlaceholder,
  secondaryItems,
  servicesSection,
  onSearch,
}: AsideMenuBaseProps) {
  return (
    <div className={cx(styles.asideMenu, { [styles.asideMenuOpen]: isOpen })}>
      <div className={styles.content}>
        <AsideMenuSearchSection placeholder={searchInputPlaceholder} onClick={onClose} onSearch={onSearch} />

        <AsideMenuFocusSection items={focusItems} />

        <AsideMenuMainSection primaryItems={primaryItems} secondaryItems={secondaryItems} />

        <AsideMenuPaperSection />

        <AsideMenuLinksSection items={servicesSection.items} title={servicesSection.title} />

        {isAuthenticated && (
          <AsideMenuLinksSection
            extraElement={
              <LinkText as="button" className={styles.logoutLink} onClick={onLogout}>
                {logoutLabel}
              </LinkText>
            }
            items={accountSection.items}
            title={accountSection.title}
          />
        )}
      </div>
    </div>
  );
}

export function AsideMenu(props: AsideMenuProps) {
  const { isAuthenticated, logout } = useAuth();
  const env = useEnv();
  const { isAsideMenuOpen, toggleIsAsideMenuOpen } = useNavigation();

  const handleLogout = async () => {
    await sendTrackingEvent(createMenuEvent({ eventlabel: 'logout' }));
    logout(getLogoutRedirectUrl(authMethod, env));
  };

  const documentRef = useRef(typeof document !== 'undefined' ? document : null);
  const keydownEventListener = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && isAsideMenuOpen) {
      toggleIsAsideMenuOpen();
    }
  };
  useEventListener('keydown', keydownEventListener, documentRef);

  return (
    <AsideMenuBase
      {...props}
      isOpen={isAsideMenuOpen}
      isAuthenticated={isAuthenticated}
      onClose={toggleIsAsideMenuOpen}
      onLogout={handleLogout}
    />
  );
}
