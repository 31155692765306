import { Caption, Icon, Paragraph } from '@mediahuis/chameleon-react';
import { ArrowForward } from '@mediahuis/chameleon-theme-wl/icons';

import type { EPaperLinkHrefTarget } from '@hubcms/domain-navigation';

import { NavigationLink } from '../NavigationLink';

import styles from './e-paper-link.module.scss';

type EPaperLinkProps = {
  label?: string;
  description?: string;
  href?: string;
  target?: EPaperLinkHrefTarget;
  imageSrc?: string;
};

export function EPaperLink({ label, description, href, target, imageSrc }: EPaperLinkProps) {
  const Component = href ? NavigationLink : 'div';
  const componentProps = href
    ? {
        href,
        target,
        rel: 'noreferrer',
        trackingName: label,
        trackingBlock: 'services-left',
      }
    : {};

  return (
    <Component {...componentProps} className={styles.ePaperLink}>
      {imageSrc && <img className={styles.image} alt="" width="62" height="62" src={imageSrc} />}
      <div className={styles.body}>
        <div>
          {label && (
            <Paragraph className={styles.title} size="lg" weight="strong" font="alt">
              {label}
            </Paragraph>
          )}
          {description && (
            <Caption className={styles.description} size="sm">
              {description}
            </Caption>
          )}
        </div>
        {href && <Icon className={styles.icon} as={ArrowForward} size="sm" />}
      </div>
    </Component>
  );
}
