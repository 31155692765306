import { Paragraph } from '@mediahuis/chameleon-react';

import { NavigationLink } from '../NavigationLink';

import styles from './navigation-focus-block.module.scss';

type NavigationFocusBlockProps = {
  href: string;
  label: string;
  target?: string;
};

export function NavigationFocusBlock({ href, label, target }: NavigationFocusBlockProps) {
  return (
    <NavigationLink
      className={styles.navigationFocusBlock}
      href={href}
      trackingBlock="navigation-menu-focus"
      trackingName={label}
      target={target}
    >
      <Paragraph className={styles.label} font="alt" weight="strong">
        {label}
      </Paragraph>
    </NavigationLink>
  );
}
