import { ePaperLinkDescription, ePaperLinkHref, ePaperLinkHrefTarget, ePaperLinkImage, ePaperLinkLabel } from '@hubcms/brand';
import { EPaperLink } from '@hubcms/ui-navigation-2';

import styles from './aside-menu.module.scss';

export function AsideMenuPaperSection() {
  if (ePaperLinkDescription || ePaperLinkLabel) {
    return (
      <section className={styles.paperSection}>
        <EPaperLink
          description={ePaperLinkDescription}
          href={ePaperLinkHref}
          imageSrc={ePaperLinkImage?.src}
          label={ePaperLinkLabel}
          target={ePaperLinkHrefTarget}
        />
      </section>
    );
  }

  return null;
}
