import { Icon, IconButton } from '@mediahuis/chameleon-react';
import { Close, Search } from '@mediahuis/chameleon-theme-wl/icons';
import cx from 'classnames';
import type { MouseEvent } from 'react';
import { useState } from 'react';

import { sendTrackingEvent } from '@hubcms/data-access-tracking';
import { createViewSearchResultsEvent } from '@hubcms/utils-tracking';

import styles from './search-bar.module.scss';

type SearchBarProps = {
  className?: string;
  placeholder?: string;
  onSubmit: (searchValue: string) => void;
};

export function SearchBar({ className, placeholder, onSubmit }: SearchBarProps) {
  const [searchValue, setSearchValue] = useState('');

  const submitForm = () => {
    sendTrackingEvent(
      createViewSearchResultsEvent({
        searchresults: true,
        searchterm: searchValue,
      }),
    );
    onSubmit(searchValue);
  };

  return (
    <form
      className={cx(styles.searchBar, className)}
      onSubmit={event => {
        event.preventDefault();
        submitForm();
      }}
    >
      <div className={styles.label}>
        <label htmlFor="search-input">{placeholder}</label>
      </div>

      <div className={styles.iconContainer}>
        <Icon className={styles.searchIcon} as={Search} size="sm" />
      </div>

      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <input
        className={styles.input}
        id="search-input"
        name="search"
        placeholder={placeholder}
        value={searchValue}
        onChange={event => setSearchValue(event.target.value)}
        onKeyDown={event => event.key === 'Enter' && submitForm()}
      />

      {searchValue && (
        <IconButton
          circular
          className={styles.closeButton}
          icon={Close}
          size="sm"
          onClick={(event: MouseEvent) => {
            event.preventDefault();
            setSearchValue('');
          }}
        />
      )}
    </form>
  );
}
